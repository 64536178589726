import React, {useState, useEffect, useCallback, useRef} from 'react';

//components
import Button from './components/Button/Button';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import Footer from './components/Footer/Footer';
import LinearGradient from './components/LinearGradient/LinearGradient';
import LoggedMessage from './components/LoggedMessage/LoggedMessage';
import TextInput from './components/TextInput/TextInput';
import Title from './components/Title/Title';

//functions
import {loadMswValue, saveMswValue} from './database/webStorage';
import {grantTokensUsingLoginAndPassword} from './logic/unlock';
import {trackEvent, storeMetricsSessionId, EventType} from './utils/metrics';
import * as Metrics from './utils/metrics';
import {parseBRFromURL, parseAppLabelFromURL} from './utils/url';

//constants
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  PARENT_LOGGED_USER_DATA,
  IS_LOCKED,
  LOCKSCREEN_SHOWUP,
  USERNAME,
  LAST_USER_ACTIVITY,
  LAST_LOGIN_TIME_ISO_STRING,
  KEY_BUSINESS_RELEASE,
} from './configs/active-env';
import {debounce, throttle} from 'lodash';

//types
type Translations = {
  loggedInAs: string;
  enterYourPin: string;
  enterYourPassword: string;
  logout: string;
  queueDescription: string;
  unlock: string;
  wrongPassword: string;
  wrongPin: string;
  inactivityWarning: string;
  serverError: string;
  turnOnWifiOrData: string;
};

const logoutInProgress = () => window.location.toString().endsWith('/logout');
const getIsUserLoggedIn = async () => {
  const loggedUserData = await loadMswValue(PARENT_LOGGED_USER_DATA);
  return !(loggedUserData === '' || loggedUserData === null || loggedUserData === undefined);
};

//if last activity is before last login it should not appear
function App() {
  const initialRender = useRef(true);
  //state
  const [login, setLogin] = useState<string>('');
  const [error, setError] = useState<string | undefined>('');
  const [isPin, setIsPin] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [passCode, setPassCode] = useState<string>('');
  const [locked, setLocked] = useState<boolean>(false);
  const [loggedUser, setLoggedUser] = useState<boolean>(false);
  const [translations, setTranslations] = useState<Translations | undefined>(undefined);

  let lastUserActivity: string = '1'; // Set some old date
  let lockScreenTime: number = 600 * 1000; //initial 600s (in ms)

  useEffect(() => {
    // only call these events on change, not on first render
    if (!initialRender.current) {
      if (!logoutInProgress()) {
        if (locked) {
          trackEvent(EventType.LockScreen);
        }
      }
    } else {
      // only call this event on first render
      getIsUserLoggedIn().then(isLoggedIn => {
        /// only if redirect back to parent
        if (isLoggedIn && window.location.toString().includes("/parent")) {
          let app_label = parseAppLabelFromURL();
          Metrics.getUserGroupId().then(usergroup_id => {
            Metrics.trackEvent(Metrics.EventType.OpenApp, {app_label, usergroup_id});
          });
        }
      });
    }
    initialRender.current = false;
  }, [locked]);

  const debouncedEventListener = useCallback(
    debounce(async (e: any) => {
      if (e?.detail?.userLoggedIn === true) {
        setLoggedUser(true); // Should trigger useEffect
        if (e?.detail?.username != null) {
          setLogin(e?.detail?.username);
        } else {
          const loginUsername = await loadMswValue(USERNAME);
          setLogin(loginUsername);
        }
      }
    }, 1000),
    [], // will be created only once initially
  );

  window.addEventListener('user_logged_in', debouncedEventListener);

  //Events indicating user activity
  const windowEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  // timer routines
  let timer: any;
  const cancelTimeout = () => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }
  };

  const saveLastUserActivity = async () => {
    //console.log(`||| saveLastUserActivity |||`);
    lastUserActivity = Date.now().toString();
    await saveMswValue(LAST_USER_ACTIVITY, lastUserActivity);
  };

  const saveLastUserActivityThrottled = throttle(saveLastUserActivity, 5000, {leading: true, trailing: false});

  const resetTimeout = async () => {
    if (timer) {
    //  console.log('>> resetting timeout');
      await saveLastUserActivityThrottled();
      clearTimeout(timer);
      timer = setTimeout(() => {
     //   console.log('Locking! (resetTimeout)');
        lockScreen(true);
      }, lockScreenTime);
      //console.log(`TIMER ID: ${timer}, lockScreenTime: ${lockScreenTime}  (resetTimeout)`);
    }
  };

  // Don't reset lastUserActivity every time, 5s (https://css-tricks.com/debouncing-throttling-explained-examples/)
  const resetTimeoutThrottled = throttle(resetTimeout, 5000, {leading: true, trailing: false});

  //Initialize lock status
  const initHandler = async (): Promise<boolean> => {
    if (!logoutInProgress()) {
      const loggedUserData = await loadMswValue(PARENT_LOGGED_USER_DATA);
      const isUserLoggedIn = await getIsUserLoggedIn();
      setLoggedUser(isUserLoggedIn);
      const isLocked = await loadMswValue(IS_LOCKED);
      const lastUserActivityStr = await loadMswValue(LAST_USER_ACTIVITY);
      const lastUserActivity = isNaN(parseInt(lastUserActivityStr)) ? 1 : parseInt(lastUserActivityStr);
      const lastUserLoginTimeStr = await loadMswValue(LAST_LOGIN_TIME_ISO_STRING);
      const lastUserLoginTime = Date.parse(lastUserLoginTimeStr);
      const loginUsername = await loadMswValue(USERNAME);
      setLogin(loginUsername);

      // console.log(`CHECK
      //   lastUserActivity + lockScreenTime: ${lastUserActivity + lockScreenTime}
      //   Date.now(): ${Date.now()}
      //   lastUserLoginTime: ${new Date(lastUserLoginTime)}
      //   lastUserActivity: ${new Date(lastUserActivity)}
      //   lastUserActivity > lastUserLoginTime: ${lastUserActivity > lastUserLoginTime}
      //   (lastUserActivity + lockScreenTime < Date.now()): ${lastUserActivity + lockScreenTime < Date.now()}
      //   lockScreenTime: ${lockScreenTime}
      //   isLocked: ${isLocked}`);
      if (
        isLocked === 'true' ||
        (lastUserActivity + lockScreenTime < Date.now() && lastUserActivity > lastUserLoginTime)
      ) {
        if (isUserLoggedIn) {
          //console.log(`Locking! (initHandler)`);
          setLocked(true);
          const parsedUserData = JSON.parse(loggedUserData);
          setUsername(parsedUserData.name);
          setTranslations(parsedUserData.messages);
          setIsPin(parsedUserData.isPin);
        }
      }
      return isUserLoggedIn;
    }
    return false;
  };

  // first timer installation & activity hooks
  useEffect(() => {
    loadMswValue(LOCKSCREEN_SHOWUP)
      .then((timeSeconds: string) => {
        const seconds = timeSeconds ? timeSeconds : '600';
        lockScreenTime = Number.parseInt(seconds) * 1000;
        //console.log(`lockScreenTime loaded: ${lockScreenTime}`);
      })
      .then(initHandler)
      .then(() => {
        disableBackground(locked);
        timer = setTimeout(() => {
          console.log('Locking! (useEffect)');
          lockScreen(true);
        }, lockScreenTime);
        //console.log(`TIMER ID: ${timer}, lockScreenTime: ${lockScreenTime}  (useEffect)`);
        windowEvents.forEach(e => window.addEventListener(e, resetTimeoutThrottled));
        return () => {
          cancelTimeout();
          windowEvents.forEach(e => window.removeEventListener(e, resetTimeoutThrottled));
          window.removeEventListener('user_logged_in', debouncedEventListener);
        };
      })
      .catch(console.warn);
  }, [locked, loggedUser, error]);

  //user is not able to manipulate with the background app
  const disableBackground = (isLocked: boolean) => {
    if (isLocked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const logoutHandler = async () => {
    //@ts-ignore
    window.allowRedirect = true;
    const currentBR = await loadMswValue(KEY_BUSINESS_RELEASE);
    //console.log(`logoutHandler currentBR: `, currentBR);
    if (currentBR) {
      window.location.assign(`/parent/${currentBR}/logout`);
    } else {
      // Attempt to use BR from URL
      const urlBR = parseBRFromURL();
      let logoutUrl = '/parent/' + urlBR + '/logout';
      window.history.replaceState({}, 'SmartOps', logoutUrl);
    }
    lockScreen(false);
    cancelTimeout();
    console.log('USER LOGGED OUT!');
  };

  //Lock app state
  const lockScreen = (lock: boolean) => {
    // The timeout should always be running, and if there is no user, we are not showing the lockscreen
    if (lock) {
      initHandler().then((isUserLoggedIn: boolean) => {
        //console.log(`lockScreen running isUserLoggedIn: ${isUserLoggedIn}`);
        setLocked(isUserLoggedIn);
        saveMswValue(IS_LOCKED, isUserLoggedIn + '');
        setError('');
      });
    } else {
      console.log(`lockScreen running lock: ${lock}`);
      setLocked(lock);
      saveMswValue(IS_LOCKED, lock + '');
    }
  };

  const unlock = async (login: string, password: string) => {
    const tokenResponse: any = await grantTokensUsingLoginAndPassword(login, password);

    let tokensData;
    if (tokenResponse) {
      tokensData = await tokenResponse.json();
    }
    if (tokenResponse?.status === 200) {
      saveMswValue(ACCESS_TOKEN, tokensData.access_token);
      saveMswValue(REFRESH_TOKEN, tokensData.refresh_token);
      storeMetricsSessionId(tokensData.session_id);
      lockScreen(false);
      cancelTimeout();
      console.log('SUCCESS! Application unlocked!');
    } else if (tokenResponse?.status === 400) {
      const wrongPinMessage = translations?.wrongPin.toString();
      const wrongPasswordMessage = translations?.wrongPassword.toString();
      setError(isPin ? wrongPinMessage : wrongPasswordMessage);
      console.log('INVALID_GRANT!', tokensData);
    } else if (tokenResponse?.status >= 500) {
      console.log(`Error tokenResponse: `, tokenResponse);
      const serverErrorMessage = translations?.serverError.toString();
      setError(serverErrorMessage);
    } else {
      const turnOnWifiOrDataMessage = translations?.turnOnWifiOrData.toString();
      setError(turnOnWifiOrDataMessage);
      console.log(`Error tokenResponse: `, tokenResponse);
    }
  };

  const unlockHandler = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    setError(' ');
    let loginVar = login;
    if (loginVar == null || loginVar === '') {
      // Reload and reset login if it is missing
      loginVar = await loadMswValue(USERNAME);
      console.log(`unlockHandler resetting login loginVar: ${loginVar}`);
      setLogin(loginVar);
    }
    unlock(loginVar, passCode);
    setPassCode('');
    e?.preventDefault();
  };

  const handleChange = (e: any) => {
    setPassCode(e.target.value);
  };

  //Render if app is locked
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: 'auto',
        zIndex: 1000,
        visibility: locked ? 'visible' : 'hidden',
      }}>
      <LinearGradient>
        <Title />
        <LoggedMessage message={translations?.loggedInAs} username={username} />
        <div style={{width: 260, alignItems: 'center', marginBottom: '1%'}}>
          <TextInput
            placeholder={isPin ? translations?.enterYourPin : translations?.enterYourPassword}
            inputValue={passCode}
            onValueChange={handleChange}
            unlockHandler={unlockHandler}
          />
          <Button onClick={unlockHandler} title={translations?.unlock} />
        </div>

        <ErrorMessage message={error ? error : translations?.inactivityWarning} />

        <Footer onLogout={logoutHandler} logoutText={translations?.logout} />
      </LinearGradient>
    </div>
  );
}

export default App;
