import {doFetch} from './fetch';
import {loadMswValue, saveMswValue} from '../database/webStorage';
import {
  ACCESS_TOKEN,
  KEY_API_GW_URL_CLEAN,
  KEY_METRICS_EVENT_SESSION_ID,
  KEY_USER_GROUP_ID,
  USERNAME,
} from '../configs/active-env';
import {updateMetricAttributesWithCountryAndFacility} from './attr_values';

export const EventType = {
  // screen lock
  LockScreen: 'lock_screen',
  // screen unlock
  UnlockScreen: 'unlock_screen',
  // regular login
  Login: 'login',
  // open app
  OpenApp: 'open_app',
};

const getSessionId = async () => {
  return await loadMswValue(KEY_METRICS_EVENT_SESSION_ID);
};

export const storeMetricsSessionId = sessionId => {
  saveMswValue(KEY_METRICS_EVENT_SESSION_ID, sessionId);
};

export const getUserGroupId = async () => {
  return await loadMswValue(KEY_USER_GROUP_ID);
};

export const loginMetricsAttributes = async () => {
  const session_id = await getSessionId();
  return {
    event_type: EventType.UnlockScreen,
    event_time: new Date().toISOString(),
    user_agent: navigator.userAgent,
    session_id,
  };
};

export const trackEvent = async (eventType, attributes) => {
  const session_id = await getSessionId();
  const username = await loadMswValue(USERNAME);
  const accessToken = await loadMswValue(ACCESS_TOKEN);
  if (eventType === EventType.OpenApp) {
    if (attributes && attributes.app_label !== 'portal') {
      attributes = await updateMetricAttributesWithCountryAndFacility(attributes);
    }
  }
  const event = {
    username,
    session_id,
    event_type: eventType,
    event_time: new Date().toISOString(),
    ...attributes,
  };
  const options = {
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(event),
    method: 'POST',
  };
  const gateway = await loadMswValue(KEY_API_GW_URL_CLEAN);
  const url = `${gateway}/smartops/um/auth/api/v1/usagelog`;
  return await doFetch(url, options);
};
